import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as configData from '../../assets/config.json';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private config: typeof configData;

  
  constructor(private http: HttpClient) {
    this.config = configData;
  }

  getTenantDetails(): { name: string, id: string } {
    return this.config.tenant;
  }
}
