<div class="section pt-0 pb-2">
  <h2 class="display-4 text-dark bg-background text-center rounded p-2">
    <img src="https://glinttoyshoppics.s3.ap-south-1.amazonaws.com/25189682-9d61-4ac3-a1b3-a2901d84b188-20230718220716095.png" alt="Logo" class="logo-icon img-fluid"> Shop by Age 
   <!-- <p class="text-center" style="font-size: 0.8rem;"> Please choose the age range of your child to view appropriate toy categories</p>-->
  </h2>

 
    <div class="row p-2">
     
        <div class="col-lg-4 col-6 mx-auto custom-pad">
          <div class="card"  (click)="navigateToAgeWiseCategory(1)">
            <div class="image-container pt-3">
              <img src="https://glinttoyshoppics.s3.ap-south-1.amazonaws.com/1.webp" class="card-img-top img-fluid custom-img" alt="Card Image">
            </div>
            
            <div class="card-body">
              <h4 class="card-title">0 to 12 months</h4>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-6 mx-auto custom-pad">
          <div class="card"  (click)="navigateToAgeWiseCategory(2)">
            <div class="image-container pt-3">
              <img src="https://glinttoyshoppics.s3.ap-south-1.amazonaws.com/2.webp" class="card-img-top img-fluid custom-img" alt="Card Image">
            </div>
            <div class="card-body">
              <h4 class="card-title">1 to 3 years</h4>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-6 mx-auto custom-pad">
          <div class="card"  (click)="navigateToAgeWiseCategory(3)">
            <div class="image-container pt-3">
              <img src="https://glinttoyshoppics.s3.ap-south-1.amazonaws.com/3.webp" class="card-img-top img-fluid custom-img" alt="Card Image">
            </div>
            <div class="card-body">
              <h4 class="card-title">4 to 5 years</h4>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-6 mx-auto custom-pad">
          <div class="card"  (click)="navigateToAgeWiseCategory(4)">
            <div class="image-container pt-3">
              <img src="https://glinttoyshoppics.s3.ap-south-1.amazonaws.com/4.png" class="card-img-top img-fluid custom-img" alt="Card Image">
            </div>
            
            <div class="card-body">
              <h4 class="card-title">6 to 7 years</h4>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-6 mx-auto custom-pad">
          <div class="card"  (click)="navigateToAgeWiseCategory(5)">
            <div class="image-container pt-3">
              <img src="https://glinttoyshoppics.s3.ap-south-1.amazonaws.com/5.png" class="card-img-top img-fluid custom-img" alt="Card Image">
            </div>
            
            <div class="card-body">
              <h4 class="card-title">8 to 10 years</h4>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-6 mx-auto custom-pad">
          <div class="card"  (click)="navigateToAgeWiseCategory(6)">
            <div class="image-container pt-3">
              <img src="https://glinttoyshoppics.s3.ap-south-1.amazonaws.com/6.webp" class="card-img-top img-fluid custom-img" alt="Card Image">
            </div>
            
            <div class="card-body">
              <h4 class="card-title">11 to 12 years</h4>
            </div>
          </div>
        </div>
    
      
    </div>
</div>
