import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./services/auth-guard.service";

const routes: Routes = [
  { path: 'home', loadChildren: () => import('./components/landing/landing.module').then(m => m.LandingModule) },
  { path: 'login', loadChildren: () => import('./components/loginpage/loginpage.module').then(m => m.LoginpageModule) },
  { path: 'product/:itemId', loadChildren: () => import('./sections/product/product.module').then(m => m.ProductModule) },
  { path: 'checkout', loadChildren: () => import('./components/cart/cart.module').then(m => m.CartModule) },
  { path: 'myaccount', loadChildren: () => import('./components/account/account.module').then(m => m.AccountModule) },
  { path: 'purchase-response', loadChildren: () => import('./components/purchase-response/purchase-response.module').then(m => m.PurchaseResponseModule) },
  { path: 'aboutus', loadChildren: () => import('./shared/aboutus/aboutus.module').then(m => m.AboutusModule) },
  { path: 'termsAndConditions', loadChildren: () => import('./shared/terms/terms.module').then(m => m.TermsModule) },
  { path: 'policy', loadChildren: () => import('./shared/policy/policy.module').then(m => m.PolicyModule) },
  { path: 'blogs', loadChildren: () => import('./components/blogs/blogs.module').then(m => m.BlogsModule) },
  { path: 'myorders', loadChildren: () => import('./components/orders/orders.module').then(m => m.OrdersModule) },
  { path: 'myorders/:status', loadChildren: () => import('./components/orders/orders.module').then(m => m.OrdersModule) },
  { path: 'admin',  canActivate: [AuthGuard], loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule) },
  { path: 'feedback', loadChildren: () => import('./components/feedback/feedback.module').then(m => m.FeedbackModule) },
  { path: '', redirectTo: 'home', pathMatch: "full" },
  { path: 'readblogs', loadChildren: () => import('./components/blogs/blogs.module').then(m => m.BlogsModule) },
  { path: 'blog/:id',loadChildren: () => import('./shared/blog/blog.module').then(m => m.BlogModule) },
  { path: 'categoriesSummary/:categoryId', loadChildren: () => import('./components/categories-summary/categories-summary.module').then(m => m.CategoriesSummaryModule) },
  { path: 'categoriesSummary', loadChildren: () => import('./components/categories-summary/categories-summary.module').then(m => m.CategoriesSummaryModule) },
  { path: 'ageBasedCatalog/:ageCategory', loadChildren: () => import('./components/age-based-catalog/age-based-catalog.module').then(m => m.AgeBasedCatalogModule) },
  { path: 'Comments', loadChildren: () => import('./components/comments/comments.module').then(m => m.CommentsModule) },
  { path: 'search', loadChildren: () => import('./services/search/search.module').then(m => m.SearchModule) },
  { path: 'toy-edit-modal', loadChildren: () => import('./components/toy-edit-modal/toy-edit-modal.module').then(m => m.ToyEditModalModule) }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: true,
      useHash: true,
      scrollPositionRestoration: "top",
      anchorScrolling: "enabled",
      onSameUrlNavigation:"reload",
      scrollOffset: [0, 64]
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
