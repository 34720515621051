import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { ToastType } from 'src/app/services/toaster';


@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  isCollapsed = true;
  isLoggedInFlag = "false";

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private toaster: ToasterService
  ) {
    router.events.subscribe(val => {
      this.isCollapsed = true;
    });

    this.authenticationService.isUserLoggedIn.subscribe((data)=>{
      this.isLoggedInFlag = data;
    })

  }


  ngOnInit(): void {
  }

  getLoginTitle() {

    return this.isLoggedin() ? "Account Settings" : "Login"

  }

  mobileView() {
    if (window.innerWidth < 992) {
      return true;
    }
    return false;
  }

  isAdminLinkVisible()
  {
    return this.isLoggedin() && this.authenticationService.isUserAdmin();
  }


  public isLoggedin() {
    return (this.isLoggedInFlag == "true");
  }

  public logout() {
    this.authenticationService.logoutUser();
    this.toaster.showToast( "You have been logged out!",ToastType.Info,3000);
    this.router.navigate([this.router.url]); 
  }

}




