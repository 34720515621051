import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from './user';


import { environment } from 'src/environments/environment';

const SERVICE_URL = environment.serviceURL;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private baseUrl = SERVICE_URL + 'users';

  constructor(private http: HttpClient) { }

  getUserByUsername(username: string): Observable<User> {
    const url = `${this.baseUrl}/${username}`;
    return this.http.get<User>(url);
  }

  updateUserByUsername(username: string, userDTO: User): Observable<User> {
    const url = `${this.baseUrl}/${username}`;
    return this.http.put<User>(url, userDTO);
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + '/');
  }

}
