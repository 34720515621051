import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  private apiUrl = environment.serviceURL; // Replace with your actual API URL
  private userId: number;
  constructor(private http: HttpClient, private authService: AuthenticationService) {
    this.userId = authService.getUserId();
  }
  addAddress(address: Address): Observable<Address> {
    return this.http.post<Address>(`${this.apiUrl}users/${this.userId}/address`, address);
  }

  updateAddress(address: Address): Observable<Address> {
    return this.http.put<Address>(`${this.apiUrl}users/${this.userId}/address/${address.id}`, address);
  }

  deleteAddress(address: Address): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}users/${this.userId}/address/${address.id}`);
  }

  getDefaultAddress(): Observable<Address | undefined> {
    return this.http.get<Address>(`${this.apiUrl}users/${this.userId}/address/default`);
  }

  getAllAddresses(): Observable<Address[]> {
    return this.http.get<Address[]>(`${this.apiUrl}users/${this.userId}/address`);
  }
}

export interface Address {
  id: number;
  userId: number;
  firstLine: string;
  secondLine: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  mobileNumber: string;
  alternateNumber: string;
  emailAddress: string;
  isDefault: boolean;
}
