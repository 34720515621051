import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  
  getOriginUrl(): string {
    return window.location.origin;
  }
}
