<div class="section-shaped my-0 skew-separator skew-mini">
  <div class="page-header page-header-mini header-filter">
    <div class="page-header-image" style="background-image: url('https://d2qnkh8blp7hc9.cloudfront.net/toys+_background.jpg');"></div>
    <div class="container">
      <div class="header-body text-center mb-7">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-lg-6 col-md-8 px-5">
            <h1 class="text-white">Hello !!</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="upper mt-3">
  <div class="container">
    <div class="col-lg-5 col-md-8 mx-auto">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4 text-light">
            <small>Login with 4-digit pin</small>
          </div>

          <!-- Login Form -->
          <form *ngIf="!isRegistering && !forgotPassword" role="form">
            <div class="form-group mb-3" [ngClass]="{ 'focused': focus === true }">
              <div class="input-group input-group-alternative">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="ni ni-mobile-button"></i> +91
                  </span>
                </div>
                <input class="form-control" placeholder="Phone Number" (blur)="focus = false" (focus)="focus = true"
                  type="tel" [(ngModel)]="authDetails.mobileNumber" required>
              </div>
            </div>
            <div class="form-group" [ngClass]="{ 'focused': focus1 === true }">
              <div class="input-group input-group-alternative">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="ni ni-lock-circle-open"></i>
                  </span>
                </div>
                <input class="form-control" placeholder="4-Digit Pin" (blur)="focus1 = false" (focus)="focus1 = true"
                  type="password" [(ngModel)]="authDetails.authPin" required>
              </div>
            </div>

            <div class="text-center">
              <button class="btn btn-primary my-4" (click)="userLogin()" type="button">Login</button>
              <button class="btn btn-success my-4" (click)="switchToRegister()" type="button">Register</button>
              <button class="btn btn-link text-muted" (click)="showForgotPassword()" type="button">Forgot Password?</button>
            </div>
          </form>

          <form *ngIf="forgotPassword" role="form">
            <div class="form-group mb-3">
              <div class="input-group input-group-alternative">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="ni ni-mobile-button"> </i> +91
                  </span>
                </div>
                <input class="form-control form-control-alternative" placeholder="Phone Number"
                  type="tel" [(ngModel)]="forgotPasswordDetails.mobileNumber">
              </div>
            </div>
            <div class="text-center">
              <button class="btn btn-primary my-4" (click)="sendNewPin()" type="button">Send New PIN</button>
              <button class="btn btn-link text-muted" (click)="cancelForgotPassword()" type="button">Cancel</button>
            </div>
          </form>

          <!-- Register Form -->
          <!-- Register Form -->
          <!-- Register Form -->
          <form *ngIf="isRegistering" role="form">
            <div class="form-group mb-3">
              <div class="input-group input-group-alternative">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="ni ni-mobile-button"> </i> +91
                  </span>
                </div>
                <input class="form-control form-control-alternative" placeholder="Phone Number"
                  type="tel" [(ngModel)]="registrationDetails.mobileNumber">
              </div>
            </div>
            <div class="form-group mb-3">
              <div class="input-group input-group-alternative">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="ni ni-single-02"></i>
                  </span>
                </div>
                <input class="form-control" placeholder="Customer Name"
                  type="text" [(ngModel)]="registrationDetails.username">
              </div>
            </div>
            <div class="form-group">
              <div class="input-group input-group-alternative">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="ni ni-lock-circle-open"></i>
                  </span>
                </div>
                <input class="form-control" placeholder="4-Digit Pin" 
                  type="password" [(ngModel)]="registrationDetails.authPin">
              </div>
            </div>
            <div class="form-group">
              <div class="input-group input-group-alternative">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="ni ni-lock-circle-open"></i>
                  </span>
                </div>
                <input class="form-control" placeholder="Confirm 4-Digit Pin" type="password" [(ngModel)]="passwordConfirmation">
              </div>
            </div>

            <!-- Request OTP Section -->
            <section>
              <div class="text-center">
                <button class="btn btn-primary my-4" (click)="requestOTP()"
                  [disabled]="otpRequested && otpCountdown !== 0" type="button">
                  Request OTP
                </button>
                <p class="text-light"  *ngIf="otpCountdown >0">{{ "Request again after " + otpCountdown + " Secs" }}</p>
              </div>
            </section>
            
            <!-- OTP Input Section -->
            <section>
              <div class="form-group">
                <div class="input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="ni ni-key-25"></i>
                    </span>
                  </div>
                  <input class="form-control" placeholder="Enter OTP" 
                    type="number" [(ngModel)]="registrationDetails.otp">
                </div>
              </div>
            </section>
            <div class="text-center">
              <button class="btn btn-primary my-4" (click)="userRegister()" [disabled]="!otpRequested"
                type="button">Register</button>
              <button class="btn btn-success my-4" (click)="switchToLogin()" type="button">Back to Login</button>
            </div>
          </form>



        </div>
      </div>
    </div>
  </div>
</section>