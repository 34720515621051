  <!-- Hidden section for search engine optimization -->
  <div class="hidden-section" style="display: none;">
    <h2>Keywords for SEO:</h2>
    <ul>
      <li>online toy shop</li>
      <li>safe toys</li>
      <li>innovative toys</li>
      <li>high-quality toys</li>
      <li>trending toys</li>
      <li>latest toys</li>
      <li>affordable prices</li>
      <li>customer satisfaction</li>
      <li>toys for kids of all ages</li>
      <li>educational toys</li>
      <li>fun-filled toys</li>
      <li>market trends</li>
      <li>top online toy shops</li>
      <li>learning through play</li>
      <li>creativity in children</li>
      <li>competitive prices</li>
      <li>trusted online toy shop</li>
      <li>user-friendly website</li>
      <li>hot toys</li>
      <li>go-to destination for toys</li>
      <li>exceptional toys</li>
      <li>outstanding service</li>
    </ul>
  </div>
  <!-- End of hidden section -->

<div class="cd-section" id="accordion">
    <div class="accordion-1">
      <div class="container">
        <div class="row">
          <div class="col-md-6 mx-auto text-center">
            <h2 class="title mb-2 mt-2 display-4">Blogs</h2>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--
  <div class="container">
    <app-blog *ngFor="let blog of blogs" [blog]="blog"></app-blog>
  </div>-->
<div class="container">


  <div *ngFor="let blog of blogs" class="card shadow border-0">
    <!-- Blog Title -->
    <h5 class="text-white text-center bg-danger p-1">{{ blog.title }}</h5>
    
    <!-- Blog Image -->
    <div class="d-flex justify-content-center">
      <img [src]="blog.imageUrl" alt="{{ blog.title }}" class="img-fluid">
    </div>

    <!-- Blog Content -->
    <div class="card-body">
      <div [innerHTML]="blog.shortContent">...</div>
      <br/>
      <div class="d-flex justify-content-end"> <!-- Align the button to the right -->
        <div class="btn btn-primary btn-sm" (click)="navigateToBlog(blog.id)">Read Full</div>
      </div>
    </div>
    <div class="card-footer">
        <p class="small text-dark">{{blog.readTime}} minute Read</p>
    </div>
  </div>
  
</div>
