import { Component, ViewChild , AfterViewInit, HostListener  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingOverlayService } from 'src/app/services/loading-overlay.service';
import { Blog, BlogDataService } from 'src/app/services/blog-data.service';
import { RecommendationsTsService } from 'src/app/services/recommendations.ts.service';
import { CatalogService } from 'src/app/services/catalog.service';
import noUiSlider from "nouislider";


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements AfterViewInit  {

  autoclose = false;

  parentScrollThresholdAboveForCategories: number = 200; // Set the value for above
  parentScrollThresholdBelowForCategories: number = 150;

  @ViewChild('videoPlayer') videoPlayer: any;
  constructor(private router: Router, private recommendations: 
    RecommendationsTsService,
    private route: ActivatedRoute, 
    private blogDataService: BlogDataService, 
    private loadingOverlayService: LoadingOverlayService,
    private catalogService: CatalogService) {
    //this.loadingOverlayService.showLoadingOverlay("Loading your catalog Items", 5000);
    //this.toasterService.showToast('Welcome to Glint Toy Shop! Thanks for visiting us. Please login to help us serve you better. And also avail latest offers!', ToastType.None, 2000);
    
  }

  blogRecommendations: Blog[];
  ngOnInit(): void {
    this.recommendations.getRecommendedBlogs(2).subscribe(val => {
      this.blogRecommendations = val;
    })
    this.catalogService.selectedAgeRange$.next(null);

    var slider = document.getElementById("sliderRegular");

    noUiSlider.create(slider, {
      start: 40,
      connect: false,
      range: {
        min: 0,
        max: 100
      }
    });

    var slider2 = document.getElementById("sliderDouble");

    noUiSlider.create(slider2, {
      start: [20, 60],
      connect: true,
      range: {
        min: 0,
        max: 100
      }
    });
    
  }

  navigateToBlog(blogId: number) {
    this.router.navigate(['blog', blogId]); // Navigate to the 'blog' route with the specified 'blogId'
  }


  ngAfterViewInit() {
    this.videoPlayer.nativeElement.play();
  }

  play() {
    this.videoPlayer.nativeElement.play();
  }

  played: boolean = false;

  scrollPosition: number = 0;
  videoPoster: string = 'https://glinttoyshoppics.s3.ap-south-1.amazonaws.com/glintToyShopLandingVideo-0.jpg';
  @HostListener('window:scroll', [])
  onWindowScroll() {
    // Get the current scroll position
    this.scrollPosition = window.scrollY;
    
    // If the video has not been played yet, and the scroll position reaches a certain threshold (e.g., 50% of the video container), play the video.
    const videoContainer = document.getElementById('videoContainer');
    const videoContainerOffsetTop = videoContainer.offsetTop;
    const videoContainerHeight = videoContainer.offsetHeight;

    if (!this.played && this.scrollPosition >= videoContainerOffsetTop + videoContainerHeight / 2) {
      this.play();
      this.played = true;
    }
  }


}
