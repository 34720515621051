import { Component, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnDestroy {
  date: Date = new Date();
  constructor() {}
  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {}

  navigateToURL(url) {
    window.open(url, '_blank');
  }

  openLogo(ClientID: string): void {
    const attributes = 'toolbar=0,location=0,directories=0,status=0, menubar=0,scrollbars=1,resizable=1,width=550,height=600,left=0,top=0';
    window.open('http://www.ccavenue.com/verifySeal.jsp?ClientID=' + ClientID, 'win', attributes);
  }

}
