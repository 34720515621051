<nav class="navbar navbar-expand-lg navbar-light bg-white pb-1">
  <div class="container pt-0">


    <a class="navbar-brand" routerLink="/home">
      <img
        src="https://glinttoyshoppics.s3.ap-south-1.amazonaws.com/a5574e8f-89fe-4254-aa49-a4cf06fff669-20230718221313036.jpg"
        alt="Glint Toy Shop Logo" class="custom-img image-fluid"  width="112" height="56px">
    </a>
    <div class="ml-auto d-flex">

      <div class="navbar-toggler pl-1 pr-1">
        <a aria-expanded="false" aria-haspopup="true" class="nav-link nav-link-icon" role="button" *ngIf="isLoggedin()"
          routerLink='checkout'>
          <i class="ni ni-cart custom-cart-icon text-secondary"></i>
        </a>
      </div>
      <div *ngIf="isLoggedin()" class="navbar-toggler pl-1 pr-1 dropdown" dropdown>
        <a aria-expanded="false" aria-haspopup="true" class="nav-link nav-link-icon dropdown-toggle dropdown-toggle"
          data-toggle="dropdown" dropdownToggle href="javascript:;" id="navbar-default_dropdown_1" role="button">
          <i class="ni ni-circle-08 custom-cart-icon text-secondary"></i>
        </a>
        <div aria-labelledby="navbar-default_dropdown_1" class="dropdown-menu dropdown-menu-right" *dropdownMenu>

          <a *ngIf="isLoggedin()" class="dropdown-item" (click)="logout()">Logout</a>
          <a *ngIf="isLoggedin()" class="dropdown-item" routerLink='myaccount'>My Account</a>
          <a *ngIf="isLoggedin()" class="dropdown-item" routerLink='myorders'>My Orders</a>
        </div>
      </div>
      <button aria-controls="navbar-default" aria-label="Toggle navigation" class="navbar-toggler"
        [attr.aria-expanded]="!isCollapsed" (click)="isCollapsed = !isCollapsed" id="navbar-default" type="button">
        <span class="navbar-toggler-icon custom-cart-icon"></span>
      </button>
    </div>


    <div class="navbar-collapse" [isAnimated]="true" [collapse]="isCollapsed" id="navbar-default">

      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a class="navbar-brand" [routerLink]="['/']">
              <img
                src="https://glinttoyshoppics.s3.ap-south-1.amazonaws.com/a5574e8f-89fe-4254-aa49-a4cf06fff669-20230718221313036.jpg"
                alt="Glint Toy Shop Logo" class="custom-img custom-image-icon image-fluid mx-auto">
            </a>
          </div>

          <div class="col-6 collapse-close">
            <button aria-controls="navbar-default" aria-label="Toggle navigation"
              class="navbar-toggler custom-image-icon" [attr.aria-expanded]="!isCollapsed"
              (click)="isCollapsed = !isCollapsed" id="navbar-default" type="button">
              <span></span> <span></span>
            </button>
          </div>
        </div>
      </div>
      <ul class="navbar-nav ml-lg-auto">

        <li *ngIf="isLoggedin()" class="nav-item dropdown" dropdown>
          <a aria-expanded="false" aria-haspopup="true" class="nav-link nav-link-icon dropdown-toggle dropdown-toggle"
            data-toggle="dropdown" dropdownToggle href="javascript:;" id="navbar-default_dropdown_1" role="button">
            <i class="ni ni-circle-08"></i>
            <span class="nav-link-inner--text d-lg-none">My Account</span>
          </a>
          <div aria-labelledby="navbar-default_dropdown_1" class="dropdown-menu dropdown-menu-right" *dropdownMenu>

            <a *ngIf="isLoggedin()" class="dropdown-item" (click)="logout()">Logout</a>
            <a *ngIf="isLoggedin()" class="dropdown-item" [routerLink]="['myaccount']">My Account</a>
            <a *ngIf="isLoggedin()" class="dropdown-item" [routerLink]="['myorders']">My Orders</a>
          </div>
        </li>
        <li *ngIf="!isLoggedin()" class="nav-item">
          <a class="nav-link nav-link-icon" routerLink='login'>
            <i class="ni ni-circle-08"></i>
            <span class="nav-link-inner--text d-lg-none">Login</span>
          </a>
        </li>

        <li *ngIf="isLoggedin()" class="nav-item">
          <a class="nav-link nav-link-icon" routerLink='checkout'>
            <i class="ni ni-cart"></i>
            <span class="nav-link-inner--text d-lg-none">Checkout</span>
          </a>
        </li>
        <li *ngIf="isAdminLinkVisible()" class="nav-item">
          <a class="nav-link nav-link-icon" routerLink='admin'>
            <i class="ni ni-settings-gear-65"></i>
            <span class="nav-link-inner--text d-lg-none">Admin</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link nav-link-icon" routerLink='readblogs'>
            <i class="fas fa-file-alt"></i>
            <span class="nav-link-inner--text d-lg-none">Blogs</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link nav-link-icon" routerLink='aboutus'>
            <i class="fas fa-info-circle"></i>
            <span class="nav-link-inner--text d-lg-none">About Us</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link nav-link-icon" routerLink='policy'>
            <i class="far fa-newspaper"></i>
            <span class="nav-link-inner--text d-lg-none">Policy</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link nav-link-icon" routerLink='termsAndConditions'>
            <i class="fas fa-gavel"></i>
            <span class="nav-link-inner--text d-lg-none">Terms & Conditions</span>
          </a>
        </li>


      </ul>
    </div>
  </div>
</nav>