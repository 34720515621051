export const environment = {
  production: true,

  //serviceURL: 'http://localhost:5000/', 
  serviceURL: 'https://test.glintcloudshops.com/',
  MERCHANT_ID: '2513554',
  ACCESS_CODE: 'AVUR72KF48AB14RUBA',
  WORKING_KEY: 'F3BFA2BD31149ACBECC23D86107DEEED',
  REDIRECT_URL: 'https://test.glintcloudshops.com/api/purchases/payment-response',
  CANCEL_URL: 'https://test.glintcloudshops.com/api/purchases/payment-response',
  sender: 'GLNTYS',
  authKey: '395174AM41i5xc64991938P1',
  apiUrl: 'https://control.msg91.com/api/v5/flow/',
  shortUrl: '1',
  number: '5',
  templateId: '64991c34d6fc057586594b22'
};
