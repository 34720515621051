<!--
  
<div class="section m-0 p-0">
    <div class="container-sm mx-auto m-0 p-0">
      <div class="row m-0 p-0">  
        <div class="col-sm-12 col-md-12 col-lg-12 text-center m-2">
          <h4 class="text-center">Toy Recommendations</h4>
          <label class="m-2">Our recommended toys based on Sankarshana's taste. Please mark the toys you like, dislike it helps us to understand and recommend toys that best suit you. </label>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 m-0 pt-2" *ngFor="let item of getCatalogItems()">
            <app-catalog-item [item]="item"></app-catalog-item>
        </div> 
      </div>
    </div>
</div>
 
-->
<div class="section m-0 p-0">
  <div class="container-sm mx-auto">
    <div class="row">
      <div class="col-12 text-center mb-1">
        <div class="bg-background">
          <h2 class="display-4 text-center rounded p-2">
            <img src="https://glinttoyshoppics.s3.ap-south-1.amazonaws.com/25189682-9d61-4ac3-a1b3-a2901d84b188-20230718220716095.png" alt="Logo" class="logo-icon img-fluid"> Trending Toys 
          </h2>
        </div>
        
      </div>
      <div class="col-6 col-lg-3 col-md-3 p-1" *ngFor="let item of visibleItems">
        <app-catalog-item [item]="item"></app-catalog-item>
      </div>
     
    </div>
  </div>
</div>