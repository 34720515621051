import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Blog, BlogDataService } from 'src/app/services/blog-data.service';
import { RecommendationsTsService } from 'src/app/services/recommendations.ts.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent {
 // @Input() blog: Blog;

  blogId: number;
  blog: Blog;
  blogRecommendations: Blog[];

  constructor(private router: Router, private recommendations: RecommendationsTsService,private route: ActivatedRoute, private blogDataService: BlogDataService) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.blogId = +params['id']; // Get the blogId from the route parameters and convert it to a number
      this.blogDataService.getBlogById(this.blogId).subscribe(blog=>{
        this.blog = blog;
      });
      this.recommendations.getRecommendedBlogs(this.blogId).subscribe(val=>{
        this.blogRecommendations = val;
      })
    });



  }

  navigateToBlog(blogId: number) {
    this.router.navigate(['blog', blogId]); // Navigate to the 'blog' route with the specified 'blogId'
  }


}

