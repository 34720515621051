import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  constructor(private http: HttpClient) {}

  
  getDiscountAmount(userId: string) {
    // Make an HTTP request to the backend API to get the discount amount based on userId
    return this.http.get<number>(`/api/coupons/${userId}`);
  }
}
