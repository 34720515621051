<div class="cd-section" id="accordion">
    <div class="accordion-1">
      <div class="container">
        <div class="row">
          <div class="col-md-6 mx-auto text-center">
            <h2 class="title mb-3 mt-5 display-4">About Us</h2>
          </div>
        </div>
       
  
      </div>
    </div>
  </div>
  
<section class="section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="accordion" id="accordionExample">
  
            <div class="card">
              <div class="card-header" id="headingOne">
                <h5 class="mb-0">
                  <button class="btn " type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Our Story
                  </button>
                </h5>
              </div>
  
              <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                <div class="card-body">
                  We are an online toy shop dedicated to providing safe, innovative, and high-quality products for children of all ages. With a focus on learning, fun, and affordability, we aim to bring the latest and trending toys to your doorstep.
                </div>
              </div>
            </div>
  
            <div class="card">
              <div class="card-header" id="headingTwo">
                <h5 class="mb-0">
                  <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Our Mission
                  </button>
                </h5>
              </div>
              <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordionExample">
                <div class="card-body">
                  Our mission is to bring joy and learning to children through safe and innovative toys. We carefully curate our product selection to ensure they meet the highest standards of quality, safety, and educational value. We strive to exceed customer expectations and provide a seamless shopping experience.
                </div>
              </div>
            </div>
  
            <div class="card">
              <div class="card-header" id="headingThree">
                <h5 class="mb-0">
                  <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Our Values
                  </button>
                </h5>
              </div>
              <div id="collapseThree" class="collapse show" aria-labelledby="headingThree" data-parent="#accordionExample">
                <div class="card-body">
                  Customer satisfaction is our utmost priority. We believe in honesty, transparency, and building long-lasting relationships with our customers. We continuously strive to provide excellent service, offer the latest and trending toys, and maintain affordable prices without compromising on quality.
                </div>
              </div>
            </div>
  
            <div class="card">
              <div class="card-header" id="headingFour">
                <h5 class="mb-0">
                  <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" href="mailto:glintlets@gmail.com">
                    Contact Us
                  </button>
                </h5>
              </div>
              <div id="collapseFour" class="collapse show" aria-labelledby="headingFour" data-parent="#accordionExample">
                <div class="card-body">
                  We are always here to assist you with any inquiries or concerns. Please feel free to contact our customer support team via <a href="mailto:glintlets@gmail.com">Email: glintlets@gmail.com</a>. We value your feedback and strive to provide prompt and helpful assistance to ensure your satisfaction.
                </div>
              </div>
            </div>
  
          </div>
        </div>
      </div>
    </div>
  </section>
  
