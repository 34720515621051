import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ElementRef, ViewChild } from '@angular/core';

declare var gtag;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {

  toastMessage: string;
  isToastVisible: boolean;

  constructor(public router: Router) {

   // const navEndEvents = router.events.pipe(
  //    filter(event => event instanceof NavigationEnd),
  //  );

  //  navEndEvents.subscribe((event: NavigationEnd) => {
   //   gtag('config', 'G-EYVRMN1PCQ', {
   //     'page_path': event.urlAfterRedirects
   //   });
   // })

  }

  ngOnInit(): void {

  }

  showAlert = true;
  closeAlert() {
    this.showAlert = false;
  }


}
