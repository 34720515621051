import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToasterService } from './toaster.service';
import { ToastType } from './toaster';

const SERVICE_URL = environment.serviceURL;
@Injectable({
  providedIn: 'root'
})
export class CartService {


  private cartApiUrl = SERVICE_URL + 'cart/users';
  private cartSubject = new BehaviorSubject<Cart>({ id: undefined, userId: 0, items: [] });
  private cart: Cart;
  //private storageKey = "cart";

  constructor(
    private authService: AuthenticationService,
    private http: HttpClient,
    private toaster: ToasterService
  ) {
   // const savedCart = localStorage.getItem(this.storageKey);
   // if (savedCart) {
    //  this.cart = JSON.parse(savedCart);
   // }

    this.authService.userDetails.subscribe((val) => {
      let id = JSON.parse(val).userId;
      this.initCart(id);
    });
  }

  public initCartForLoggedInUser()
  {
    this.authService.userDetails.subscribe((val) => {
      let id = JSON.parse(val).userId;
      this.http.get<Cart>(`${this.cartApiUrl}/${id}`)
      .pipe(
        tap((cart: Cart) => {
          this.cart = cart;
          this.cartSubject.next(cart);
        })
      )
    });
  }

  isItemInCartFromItemId(cartItemId: Number): Observable<boolean> {
    return this.isItemInCart({
      id: null,
      itemId: Number(cartItemId),
      name: null,
      price: null,
      tax: null,
      quantity: null,
      discount: null
    })
  }

  isItemInCart(item: CartItem): Observable<boolean> {
    // Get the current cart
    const cart$ = this.getCart();

    // Check if the item is in the cart
    const itemInCart$ = cart$.pipe(
      map((cart: Cart) => {
        // Check if any of the items in the cart have the same ID as the provided item
        return cart.items.some((cartItem) => Number(cartItem.itemId) === Number(item.itemId));
      })
    );

    // Return an observable of the result
    return itemInCart$;
  }
  initCart(userId: number): void {
    this.http.get<Cart>(`${this.cartApiUrl}/${userId}`)
      .pipe(
        tap((cart: Cart) => {
          this.cart = cart;
          this.cartSubject.next(cart);
     //     this.saveCartToStorage();
        })
      )
      .subscribe();
  }

  getCart(): Observable<Cart> {
    return this.cartSubject.asObservable();
  }

  addToCart(userId: number, item: CartItem): Observable<boolean> {

    let existingItem = undefined;

    if (this.cart != undefined) {
      this.cart.items.find((cartItem) => cartItem.itemId === Number(item.itemId));
    }


    if (existingItem) {
      existingItem.quantity = item.quantity;
      return this.updateCartItem(userId, existingItem);
    } else {
      return this.createCartItem(userId, item);
    }
  }

  private createCartItem(userId: number, item: CartItem): Observable<boolean> {

    return this.http.post<Cart>(`${this.cartApiUrl}/${userId}`, item)
      .pipe(
        catchError((error) => {
          // Handle the error response here
          this.toaster.showToast(JSON.stringify("Due to high demand, the item is out of stock. Sorry for your trouble."),ToastType.Error,5000);
          // You can perform additional error handling or throw a custom error if needed
          return throwError('Error creating cart item');
        }),
        tap((cart: Cart) => {
          if (cart) {
            this.cart = cart;
            this.cartSubject.next(cart);
         //   this.saveCartToStorage();
          }
        }),
        map((cart: Cart) => !!cart)
      );
  }

  private updateCartItem(userId: number, item: CartItem): Observable<boolean> {
    return this.http.put<Cart>(`${this.cartApiUrl}/${userId}/${item.itemId}`, item)
      .pipe(
        catchError((error) => {
          // Handle the error response here
          this.toaster.showToast(JSON.stringify("Due to high demand, the item is out of stock. Sorry for your trouble."),ToastType.Error,5000);
          // You can perform additional error handling or throw a custom error if needed
          return throwError('Error creating cart item');
        }),
        tap((cart: Cart) => {
          if (cart) {
            this.cart = cart;
            this.cartSubject.next(this.cart);
         //   this.saveCartToStorage();
          }
        }),
        map((cart: Cart) => !!cart)
      );
  }

  removeFromCart(userId: number, itemId: number): Observable<boolean> {
    const itemIndex = this.cart.items.findIndex((cartItem) => cartItem.id === Number(itemId));
    if (itemIndex !== -1) {
      const removedItem = this.cart.items.splice(itemIndex, 1)[0];
      return this.deleteCartItem(userId, removedItem.id);
    } else {
      const myObservable = of(false);
      return myObservable;
    }
  }

  deleteCartItem(userId: number, itemId: number): Observable<boolean> {
    return this.http.delete<Cart>(`${this.cartApiUrl}/${userId}/${itemId}`)
      .pipe(
        tap((cart: Cart) => {
          if (cart) {
            this.cart = cart;
            this.cartSubject.next(cart);
           // this.saveCartToStorage();
          }
        }),
        map((cart: Cart) => !!cart)
      );
  }

  getCartItem(item: CartItem): CartItem {
    return this.cart.items.find((cartItem) => Number(cartItem.itemId) === Number(item.itemId));
  }

 // private saveCartToStorage(): void {
 //   localStorage.setItem('cart', JSON.stringify(this.cart));
 // }
}

interface CartItem {
  id: number;
  itemId: number;
  name: string;
  price: any;
  tax: number;
  quantity: number;
  discount: number;
}

interface Cart {
  id: number;
  userId: number;
  items: CartItem[];
}
