<footer class="footer text-light">
  <div class="container">
    <!--<div class="row justify-content-center align-items-center mb-2 mt-2">
      <div class="col-12">
        <a href="javascript:void(0)" (click)="openLogo('2513554')">
          <img src="http://www.ccavenue.com/images/460.gif" border=0 class="img-fluid text-center" width="400px" height="100px">
        </a>
      </div>
    </div>-->
    <div class="row row-grid align-items-center mb-5 mt-2">
      <div class="col-lg-6">
        <h5 class="text-light font-weight-light mb-2">
          Thank you for visiting us! Happy Playing!
        </h5>

      </div>
      
      <div class="col-lg-6 text-lg-center btn-wrapper">
       
        <a href="https://www.facebook.com/glinttoyshops" target="_blank">
          <button type="button" class="button btn-icon-only rounded-circle btn btn-facebook">
            <span class="btn-inner--icon"><i class="fab fa-facebook"></i></span>
          </button>
        </a>
        &nbsp;
        <a href="https://www.instagram.com/glintlets" target="_blank">
          <button type="button" class="button btn-icon-only rounded-circle btn btn-instagram">
            <span class="btn-inner--icon"><i class="fab fa-instagram"></i></span>
          </button>
        </a>  
        &nbsp;      
        <a href="https://www.youtube.com/@GlintToyShop" target="_blank">
          <button type="button" class="button btn-icon-only rounded-circle btn btn-youtube">
            <span class="btn-inner--icon"><i class="fab fa-youtube"></i></span>
          </button>
        </a>
        
         
      </div>
    </div>
    <hr />

    <div class="row align-items-center justify-content-md-between">
      <div class="col-md-6">
        <div class="copyright">
          © {{ date | date:'yyyy'  }} <br/>
          <a class="text-light" [routerLink]="['/']">Glint Cloudshops</a> <br/>
          <a class="text-light" [routerLink]="['/']">Reg No. UDYAM-KR-03-0262033</a>
        </div>
      </div>
      <div class="col-md-6 mb-2 mt-2 ">
        <ul class="nav nav-footer justify-content-center">
          <li class="nav-item">
            <a class="nav-link text-light font-weight-light" [routerLink]="['/policy']"> Policy </a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-light font-weight-light" [routerLink]="['/aboutus']"> About Us </a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-light font-weight-light" [routerLink]="['/readblogs']">
              Blog
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-light font-weight-light" [routerLink]="['/termsAndConditions']"> Terms & Conditions </a>
          </li>
        </ul>
      </div>
    </div>
    
  </div>
</footer>