<section class="section section-lg">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2 class="mb-4">Policy</h2>
          <p>At Glint Toy Store, we strive to provide the best products and services to our customers. Please take a moment to review our policy regarding toy quality, returns, refunds, website-related issues, and customer support.</p>
  
          <h3>Toy Quality Assurance</h3>
          <p>We ensure that all toys are thoroughly inspected for quality and safety before dispatch. Our team conducts rigorous checks to ensure that the toys meet the highest standards. We prioritize the well-being and happiness of our customers, especially children, and take every measure to provide safe and enjoyable toys.</p>
  
          <h3>Returns and Refunds</h3>
          <p>Due to the nature of our products, we do not accept returns or provide refunds unless the toy is damaged during transit. In such cases, please contact us within 10 days from the date of delivery, and we will work with you to resolve the issue.</p>
  
          <h3>Payment Related Issues</h3>
          <p>If you encounter any payment-related issues while placing an order, please reach out to us at glintlets@gmail.com. We will promptly address any concerns and assist you in resolving the payment-related problems.</p>
  
          <h3>Website-Related Issues</h3>
          <p>If you come across any issues while browsing or using our website, please don't hesitate to contact us at glintlets@gmail.com. Whether it's a technical glitch, navigation problem, or any other website-related issue, our dedicated support team will investigate and resolve it as quickly as possible to ensure a smooth and enjoyable shopping experience.</p>
  
          <h3>Customer Support and Satisfaction</h3>
          <p>Customer satisfaction is our utmost priority. If you have any other issues, concerns, or questions regarding our products, services, or the website, please don't hesitate to contact us at glintlets@gmail.com. Kindly provide your mobile number in the email, and our dedicated support team will get back to you as soon as possible.</p>
          <p>Rest assured that we value your satisfaction and will make every effort to address any queries or concerns promptly and effectively.</p>
  
          <p>Thank you for choosing Glint Toy Store. We appreciate your trust and look forward to serving you with the best toys, seamless website experience, and excellent customer support.</p>
        </div>
      </div>
    </div>
  </section>
  