<!--

<div class="card">
  <div class="card-body">
    <h5 class="display-6 display-sm-5 card-title mb-1 line-clamp-2">{{ item.name }}</h5>
    <br *ngIf="item.name.length < 40"/>
    <a [routerLink]="['/product', item.id]">
      <img src="{{ item.photoLinks[0] }}" alt="..." class="img-fluid mb-1" />
      <div class="row">
        <div class="col">
          <div class="d-flex justify-content-between align-items-center">
            <div class="price">
              <h5 class="original-price"><span class="text-danger h5 h-sm-5">{{ item.price.amount | currency:
                  'INR':'symbol-narrow' }}</span></h5>
              <p class="text-success discounted-price h6 h-sm-5">{{ calculateDiscountedPrice(item.price.amount, 20) |
                currency: 'INR':'symbol-narrow' }}</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="bottom-row d-flex justify-content-between">
            <div *ngIf="isOutofStock()" class="text-danger">
              <h6 class="text-danger">Out of Stock</h6>
            </div>

            <div *ngIf="isAddedToCart()" class="text-success">
              <h6 class="text-success">Item in cart</h6>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>

-->
<div *ngIf="!isMoreLink" class="card rounded p-1">

  <a (click)="openProduct()">
    <div class="position-relative" [ngClass]="{'out-of-stock': isOutofStock()}">
      <img src="{{ item.thumbnail }}" alt="..." class="card-img-top mx-auto d-block img-fluid" />
    </div>
  </a>
  <div class="card-header m-0 p-0">
    <div class="d-flex justify-content-center p-0 ml-2">
      <div class="out-of-stock-message">
        <div *ngIf="isOutofStock()" class="text-danger">
          <p class="text-danger smaller-text"><strong>Out of Stock</strong></p>
        </div>
        <div *ngIf="isAddedToCart()" class="text-success">
          <p class="text-success smaller-text"><strong>Item in cart</strong></p>
        </div>
      </div>
    </div>

  </div>

  <div class="card-footer">
    <p class="card-title mb-0 two-line">{{ item.name | slice: 0:18 }}{{ item.name.length > 18 ? '...' : '' }}</p>
    <a>
      <div class="row">
        <div class="col-12">
          <div class="d-flex justify-content-between align-items-center">
            <div *ngIf="hasDiscount(item.discount)" class="price d-flex align-items-center">
              <h5 class="original-price mr-2"><span class="text-danger h5 h-sm-5">{{ item.price.amount | currency:
                  'INR':'symbol-narrow' }}</span></h5>
              <h4 class="text-success discounted-price h4 h-sm-5">{{ calculateDiscountedPrice(item.price.amount,
                item.discount?.discountPercent) | currency: 'INR':'symbol-narrow' }}</h4>
            </div>
            <div *ngIf="!hasDiscount(item.discount)" class="price d-flex align-items-center">
              <h4 class="mr-2"><span class="text-danger h4 h-sm-5">{{ item.price.amount | currency:
                  'INR':'symbol-narrow' }}</span></h4>
            </div>
            <!--<div class="cart-icon" id="cart-icon" (click)="addToCart(item)">
              <i class="ni ni-cart" style="font-size: 20px;"></i>
            </div>-->
          </div>
        </div>
      </div>
    </a>
  </div>
</div>

<div *ngIf="isMoreLink" class="card rounded p-1 d-flex flex-column">

  
    <div class="position-relative img-wrapper">
      <img
        src="https://glinttoyshoppics.s3.ap-south-1.amazonaws.com/25189682-9d61-4ac3-a1b3-a2901d84b188-20230718220716095.png"
        alt="..." class="card-img-top mx-auto d-block img-fluid" />
      <div class="overlay"></div>
      <div class="view-more-link text-center">
        <h4 class="text-danger text-center"><strong><i class="fas fa-angle-double-right"></i> <br/> View More </strong></h4>
      </div>
      <div class="d-flex justify-content-center p-0 ml-2">
        <p class="card-title mb-0 two-line">&nbsp;</p>
      </div>
      <p class="card-title mb-0 two-line">&nbsp;</p>
      <p class="card-title mb-0 two-line">&nbsp;</p>
      <p class="card-title mb-0 two-line">&nbsp;</p>
      <div class="row pb-2">
        <div class="col-12">
          <div class="d-flex justify-content-center align-items-center">
           
          </div>
        </div>
      </div>
    </div>
 
</div>