<section class="section section-lg">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2 class="mb-4">Terms and Conditions</h2>
          <p>Welcome to our online toy shop website. These terms and conditions outline the rules and regulations for the use of our website.</p>
          <p>By accessing this website, we assume you accept these terms and conditions. Do not continue to use our website if you do not agree to all the terms and conditions stated on this page.</p>
  
          <h3>License and Website Access</h3>
          <p>We grant you a limited license to access and make personal use of this website. You are not allowed to download or modify any portion of the website without our prior written consent.</p>
          <p>This license does not include any resale or commercial use of this website or its contents. You may not use any data mining, robots, or similar data gathering and extraction tools.</p>
  
          <h3>User Obligations</h3>
          <p>By using our website, you agree to comply with all applicable laws and regulations. You agree not to use the website for any unlawful or fraudulent purposes.</p>
          <p>You are solely responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account.</p>
  
          <h3>Product Information and Pricing</h3>
          <p>We strive to provide accurate product descriptions and pricing information on our website. However, we do not warrant that the product descriptions, images, or pricing are accurate, complete, reliable, current, or error-free.</p>
          <p>We reserve the right to modify or discontinue any product or service without prior notice. We shall not be liable to you or any third party for any modification, price change, suspension, or discontinuance of products or services.</p>
  
          <h3>Shipping and Delivery</h3>
          <p>We aim to deliver products within the specified timeframes. However, we cannot guarantee exact delivery dates and shall not be held responsible for any delays caused by factors beyond our control.</p>
          <p>Shipping charges, if applicable, will be mentioned during the checkout process. Any additional taxes or customs duties shall be borne by the customer.</p>
  
          <h3>Return and Refund Policy</h3>
          <p>For information regarding our return and refund policy, please refer to the dedicated page on our website.</p>
  
          <h3>Limitation of Liability</h3>
          <p>We shall not be liable for any indirect, incidental, special, consequential, or exemplary damages arising out of your use of our website or the products purchased from us.</p>
          <p>In no event shall our liability exceed the purchase price of the product in question.</p>
  
          <h3>Governing Law and Jurisdiction</h3>
          <p>These terms and conditions are governed by and construed in accordance with the laws of India. Any disputes arising out of these terms and conditions shall be subject to the exclusive jurisdiction of the courts located in [insert appropriate city in India].</p>
          <p>If any provision of these terms and conditions is deemed invalid or unenforceable, the remaining provisions will continue in full force and effect.</p>
  
          <p>By using our website, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions.</p>
        </div>
      </div>
    </div>
  </section>
  