import { Component, OnInit, OnDestroy } from "@angular/core";
import { Observable, Subscription, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthenticationService } from '../../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToasterService } from "src/app/services/toaster.service";
import { ToastType } from "src/app/services/toaster";
import { LoadingOverlayService } from "src/app/services/loading-overlay.service";
import { SMSService } from "src/app/services/sms.service";

@Component({
  selector: "app-loginpage",
  templateUrl: "loginpage.component.html"
})
export class LoginpageComponent implements OnInit, OnDestroy {
  focus;
  focus1;
  focus2;
  focus3;
  focus4;
  returnUrl: string;
  isRegistering: boolean = false; // Flag to indicate if user is in registration mode
  passwordConfirmation: string = ''; // Password confirmation input field value
  otpRequested: boolean = false; // Flag to indicate if OTP is requested
  otpVerificationDisabled: boolean = true; // Flag to disable OTP verification button
  otpVerified: boolean = false; // Flag to indicate if OTP is verified
  otpCountdown: number = 0; // Countdown timer for OTP button
  private countdownSubscription: Subscription; // Subscription for countdown timer

  authDetails: any = {
    mobileNumber: "",
    authPin: "",
    username: "",
  };

  registrationDetails: any = {
    mobileNumber: "",
    authPin: "",
    username: "",
    otp: ""
  };

  errorMessage: string = ''; // Error message to display

  // Page loading flag
  pageLoading: boolean = false;


  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private toaster: ToasterService,
    private loadingService: LoadingOverlayService,
    private smsService: SMSService
  ) {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
  }

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("login-page");
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("login-page");
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }

  // When someone clicks on the login button
  public userLogin() {
    // Check if there are valid credentials in the input fields, if not, do nothing
    if (!this.isValidCredentials()) {
      return;
    }

    // Set pageLoading to true and make an API call
    this.pageLoading = true;
    console.log("User login is attempted");
    this.loadingService.showLoadingOverlay();
    // Authenticate user with the provided credentials
    this.authenticationService.authenticateUser(this.authDetails.mobileNumber, this.authDetails.authPin);

    this.authenticationService.success$
      .subscribe(
        (authenticated: string) => {
          if (authenticated === "true") {
            this.navigateToBackPage();
            this.loadingService.hideLoadingOverlay();
            this.toaster.showToast("You have logged in succesfully. ", ToastType.Success, 2000);
          } else if (authenticated === "") {
            // do nothing. 
          } else {
            // Authentication failed, show error message or handle accordingly
            console.log("Authentication failed");
            this.loadingService.hideLoadingOverlay();
            this.toaster.showToast("Authetication failed. Please retry or reach out to glintlets@gmail.com", ToastType.Error, 5000);

          }
          this.pageLoading = false;
        },
        (error) => {
          console.log("Error occurred while authenticating user:", error);
          this.pageLoading = false;
          this.loadingService.hideLoadingOverlay();
        }
      );
  }

  public verifyOTP(): boolean {
    if (!this.registrationDetails.otp) {
      console.log("Please enter the OTP received.");
      this.toaster.showToast("Please enter the OTP received.", ToastType.Error, 10000);
      return false;
    }

    if (isNaN(Number(this.registrationDetails.otp)) || (this.registrationDetails.otp + "").length !== 6) {
      console.log("Please enter a valid OTP.");
      this.toaster.showToast("Please enter a valid OTP!", ToastType.Error, 10000);
      return false;
    }

    if (!(this.registrationDetails.otp + "" === this.responseOTP)) {
      this.toaster.showToast("OTP entered is wrong. Please verify!", ToastType.Error, 10000);
      return false;
    }
    this.otpVerified = true;
    this.otpVerificationDisabled = true;
    console.log("OTP Verification Successful");
    return true;

  }

  // When someone clicks on the register button
  public userRegister() {
    // Check if there are valid credentials in the input fields and if password confirmation matches
    if (!this.isValidCredentialsForRegistration() || !this.isValidPasswordConfirmationForRegistration() || !this.isValidUserNameForRegistration()) {
      this.toaster.showToast("Invalid Credential. Please Correct and Retry!", ToastType.Warn, 5000);
      return;
    }

    //TODO: 

    if (!this.verifyOTP()) {
      return;
    }

    // Set pageLoading to true and make an API call
    this.pageLoading = true;
    console.log("User registration is attempted");
    this.loadingService.showLoadingOverlay();
    this.authenticationService.resetSuccessSubject();
    // Register the user with the provided credentials
    this.authenticationService.registerUser(this.registrationDetails.username, this.registrationDetails.mobileNumber, this.registrationDetails.authPin);


    this.authenticationService.success$
      .subscribe(
        (registered: any) => {
          if (registered === "true") {
            console.log("User registered successfully!");
            // Optionally, you can automatically log in the user after registration
            // this.userLogin();
            this.toaster.showToast("Thanks for registering with Glint Toy Shop!.", ToastType.Success, 10000);
            this.loadingService.hideLoadingOverlay();
            this.navigateToMyAccountPage();

          } else if (registered == "") {
            // do nothing. 

          }
          else {
            // Registration failed, show error message or handle accordingly
            console.log("Registration failed");
            this.toaster.showToast(registered.error, ToastType.Error, 5000);
            this.pageLoading = false;
            this.loadingService.hideLoadingOverlay();

          }


        }
      );
  }



  // Validate if the input fields have valid credentials
  private isValidCredentials(): boolean {
    return !!this.authDetails.mobileNumber && !!this.authDetails.authPin;
  }

  private isValidCredentialsForRegistration(): boolean {
    return !!this.registrationDetails.mobileNumber && !!this.registrationDetails.authPin;
  }

  private isValidUserNameForRegistration(): boolean {
    return !!this.registrationDetails.username;
  }

  private isValidPasswordConfirmationForRegistration(): boolean {
    return this.registrationDetails.authPin === this.passwordConfirmation;
  }


  // Switch to registration mode
  public switchToRegister() {
    this.isRegistering = true;
    this.errorMessage = '';
  }

  // Switch back to login mode
  public switchToLogin() {
    this.isRegistering = false;
    this.errorMessage = '';
    this.passwordConfirmation = '';
    this.isRegistering = false;
    this.registrationDetails.otp = '';
    this.otpRequested = false;
    this.otpVerificationDisabled = true;
    this.otpVerified = false;
  }


  private navigateToBackPage() {
    // Redirect back to the original route
    //this.navigateToMyAccountPage();
    this.router.navigateByUrl(this.returnUrl);
    // TODO: Depending on the origin of the login page, go to the back page.
  }

  private navigateToMyAccountPage() {
    if (this.returnUrl != undefined) {
      this.navigateToBackPage();
    } else {
      this.router.navigate(['/myaccount']);
    }

  }




  /**************************************************************** */

  otpInput: string = ''; // Separate variable to store OTP input value


  private responseOTP: any;

  requestOTP(): void {
    if (this.otpRequested && this.otpCountdown !== 0) {
      return;
    }

    if (!this.isValidCredentialsForRegistration()) {
      this.toaster.showToast('Invalid Credential. Please Correct and Retry!', ToastType.Error, 5000);
      return;
    }

    if (!this.isValidPasswordConfirmationForRegistration()) {
      this.toaster.showToast('Passwords do not match. Please Correct and Retry!', ToastType.Error, 5000);
      return;
    }

    this.smsService.sendSMS(this.registrationDetails.mobileNumber).then((success) => {
      if (success) {
        this.smsService.latestOTP$.subscribe((otp) => {
          if (otp) {
            this.responseOTP = otp;
            this.otpRequested = true;
            this.otpCountdown = 30;

            this.countdownSubscription = timer(0, 1000)
              .pipe(
                take(31),
                map((value: number) => 30 - value)
              )
              .subscribe((countdown: number) => {
                this.otpCountdown = countdown;
                if (countdown === 0) {
                  this.countdownSubscription.unsubscribe();
                }
              });
          }
        });
      } else {
        // this.toaster.showToast('Failed to send SMS', ToastType.Error, 5000);
      }
    });
  }


  // ############## forgot pin ##################

  forgotPassword: boolean = false;
  forgotPasswordDetails = {
    mobileNumber: ''
  };
  
  
  // Existing methods
  
  showForgotPassword(): void {
    this.forgotPassword = true;
  }
  
  cancelForgotPassword(): void {
    this.forgotPassword = false;
  }
  
  sendNewPin(): void {
    this.smsService.sendCustomerPin(this.forgotPasswordDetails.mobileNumber).then((success)=>{
      if (success) {
        this.forgotPassword = false;
        //this.authDetails.mobileNumber = this.forgotPasswordDetails.mobileNumber;
      }
    });
  }

}
