import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Category } from 'src/app/services/category';
import { CategoryService } from 'src/app/services/category.service';
import KidsAge from 'src/app/services/kids-age';
import { Toy } from 'src/app/services/toy';

@Component({
  selector: 'app-age-based-catalog',
  templateUrl: './age-based-catalog.component.html',
  styleUrls: ['./age-based-catalog.component.scss']
})
export class AgeBasedCatalogComponent {
  @Input() ageCategory: string;
  ageCategoryIndex: number | undefined;
  ageCategoryString: string | undefined;
  toys: Toy[] = [];
  filteredToys: Toy[] = [];
  categorySelected: Category | undefined;

  constructor(private route: ActivatedRoute, private categoryService: CategoryService) {}

  ngOnInit(): void {
    if (this.ageCategory) {
      // Map age category to index using KidsAge enum
      this.ageCategoryIndex = KidsAge[this.ageCategory as keyof typeof KidsAge];
      this.ageCategoryString = this.getCategoryString(this.ageCategory);
      this.loadAgeBasedCategories();
    } else {
      this.route.params.subscribe(params => {
        const routeAgeCategory = params['ageCategory'];
        if (routeAgeCategory) {
          this.ageCategory = routeAgeCategory;
          // Map age category to index using KidsAge enum
          this.ageCategoryIndex = KidsAge[routeAgeCategory as keyof typeof KidsAge];
          this.ageCategoryString = this.getCategoryString(routeAgeCategory);
          this.loadAgeBasedCategories();
        }
      });
    }
  }

  navigateToMore(selectedCategory: Category)
  {
    this.categorySelected = selectedCategory;
    this.filteredToys = this.toys.filter(toy => toy.categories && toy.categories.includes(selectedCategory.id));
  }

  private getCategoryString(ageCategory: string) {
    let ageWiseRange: string;
    switch (ageCategory) {
      case "ZEROTOONE":
        ageWiseRange = "Zero to One";
        break;
      case "TWOTOTHREE":
        ageWiseRange = "Two to Three";
        break;
      case "FOURTOFIVE":
        ageWiseRange = "Four to Five";
        break;
      case "SIXTOSEVEN":
        ageWiseRange = "Six to Seven";
        break;
      case "EIGHTTOTEN":
        ageWiseRange = "Eight to Ten";
        break;
      case "ELEVENTOTWELVE":
        ageWiseRange = "Eleven to Twelve";
        break;
      default:
        break;
    }
    return ageWiseRange;

  }

  categories: Category[] = []; // Adjust the type of categories
  allCategories: Category[] = [];
  public loadAgeBasedCategories()
  {
    // fetch all categories from category service. 
    this.categoryService.categories$.subscribe((value: Category[]) => {
      this.allCategories = value;
         // Map kidsAge property based on ageCategoryIndex
      // Filter categories based on kidsAge property
        if (this.ageCategoryIndex !== undefined) {
          this.allCategories.forEach(category=>{
            console.log(category.kidsAge  +  this.ageCategoryIndex);
            const index = Object.values(KidsAge).indexOf(category.kidsAge);
            if(index === this.ageCategoryIndex) {
              this.categories.push(category);
            }
          })
        }

        // after categories are loaded. load toys. 
        if(this.categories !== undefined) {
          this.categories.forEach(category=>{
            this.categoryService.getToysByCategoryId(category.id).subscribe(value =>{
              this.toys.push(... value);
            })
          })
        }

    });

    

  }

}
