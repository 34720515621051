import { Component, OnInit } from '@angular/core';
import { CatalogService } from 'src/app/services/catalog.service';
import { LoadingOverlayService } from 'src/app/services/loading-overlay.service';
import { RecommendationsTsService } from 'src/app/services/recommendations.ts.service';

@Component({
  selector: 'app-catalog-items',
  templateUrl: './catalog-items.component.html',
  styleUrls: ['./catalog-items.component.scss']
})
export class CatalogItemsComponent implements OnInit {
  allItems: any[]; // Replace with the actual array of items
  visibleItems: any[];
  isLoading: boolean = true;


  constructor(private loadingService: LoadingOverlayService,
     private catalogService:CatalogService,
      private recommendationsService: RecommendationsTsService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.loadingService.showLoadingOverlay("loading",4000);
    this.catalogService.getFeaturedList().subscribe((data)=>{
      this.isLoading = true;
      this.allItems = data;
      this.visibleItems = this.allItems;
      this.loadingService.hideLoadingOverlay();
    });

   // setTimeout(() => {
      // Load initial items (4 or 5 items)
     
    //}, 1000);

    // Attach a scroll event listener to trigger loading more items
    //window.addEventListener('scroll', this.onScroll.bind(this));
  }
  public items: any[];

  getCatalogItems()
  {
   
    console.log(JSON.stringify(this.items));
    return this.items;
    //return [];
  }

  navigateToDetailsPage() 
  {

  }

   loadItems(startIndex: number, count: number) {
    this.visibleItems = this.allItems.slice(startIndex, startIndex + count);
    this.isLoading = false;
  }

  private loadingComplete:boolean  = false;

 /* onScroll() {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      !this.isLoading && !this.loadingComplete
    ) {
      this.isLoading = true;
      // Simulate an asynchronous data loading
      setTimeout(() => {
        // Load more items (4 or 5 items)
        const startIndex = this.visibleItems.length;
     //   this.loadItems(startIndex, 6);
        this.isLoading = false;
      }, 4000);
    }
  }*/

}
