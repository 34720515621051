import { Component, Input, OnInit  } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { CatalogService } from 'src/app/services/catalog.service';
import { Category } from 'src/app/services/categoryToy';
import { RecommendationsTsService } from 'src/app/services/recommendations.ts.service';

@Component({
  selector: 'app-catalog-item',
  templateUrl: './catalog-item.component.html',
  styleUrls: ['./catalog-item.component.scss']
})
export class CatalogItemComponent implements OnInit {
  
  constructor(private router: Router, private cartService: CartService, private catalog: CatalogService,
    private recommendationService: RecommendationsTsService) { 
  }

  @Input()
  item : any;

  @Input()
  isMoreLink: boolean;

  @Input()
  categoryId: number;

  @Input()
  ageCategory: string | null;

  @Input()
  category: Category;

  @Input()
  itemsFromSameCategory: any[];

  ngOnInit(): void {
   
    if(this.item!== undefined) {
      this.catalog.getStockCount(this.item.id).subscribe(val=>{
        this.stockCount = val;
       
      })
      this.catalog.fetchStockCount(this.item.id);   
    }

    this.catalog.selectedCategory$.subscribe(val=>{
      if((val!== null) && (this.categoryId==null)) {
        this.categoryId = val
      }
    })
  }

  

  openProduct() {
    if(this.ageCategory!== null) {
      this.router.navigate(['/product', this.item.id], { queryParams: { ageCategory: this.ageCategory } });
    }else 
    {
      this.router.navigate(['/product', this.item.id]);
    }
  }

  public stockCount: number = 0;

  public isOutofStock() {
   return !(this.stockCount>0);
  }


  private addedToCartFlag: boolean = false;

  public isAddedToCart() {
    this.cartService.isItemInCartFromItemId(this.item.id).subscribe(val=>{
      this.addedToCartFlag = val;
    })
    return this.addedToCartFlag;
  }

  calculateDiscountedPrice(amount: number, percentVal: number)
  {
    return amount * ((100-percentVal)/100);
  }


  
  public hasDiscount(discount: any): boolean
  {
    if((discount == null) || (discount == undefined) || (discount.discountPercent == 0 ) ) {
      return false;
    } 
    return true;
  }
  
}
