<div class="container">
  <!--<section class="section mt-0 p-0">
    <div class="container p-0">
      <div class="row">
        <div class="col-md-12 col-lg-12 mt-0">
          <div id="videoContainer">
            <video #videoPlayer class="img-fluid rounded shadow" loop muted autoplay playsinline oncanplay="this.play()"
              onloadedmetadata="this.muted = true">
              <source src="https://glinttoyshoppics.s3.ap-south-1.amazonaws.com/glintToyShopLandingVideo.mp4"
                type="video/mp4">
              Your browser does not support the video tag.
            </video>          
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <section class="section mt-0 p-0">
    <div class="rounded overflow-hidden transform-perspective-left">
      <carousel [showIndicators]="true">
        <slide>
          <div class="container p-0">
            <div class="row">
              <div class="col-md-12 col-lg-12 mt-0">
                <div id="videoContainer">
                  <video #videoPlayer class="img-fluid rounded" loop muted autoplay playsinline oncanplay="this.play()"
                    onloadedmetadata="this.muted = true">
                    <source src="https://glinttoyshoppics.s3.ap-south-1.amazonaws.com/glintToyShopLandingVideo.mp4"
                      type="video/mp4">
                    Your browser does not support the video tag.
                  </video>          
                </div>
              </div>
            </div>
          </div>
        </slide>
      
      </carousel>
    </div>
   
  </section>
  
  



<section class="mt-0 pb-0 pt-1">
  <app-catalog-items></app-catalog-items>
       <!--<app-categories-summary [scrollThresholdAbove] = 2500 [scrollThresholdBelow]= 3000 [maxItems]=5></app-categories-summary>-->
</section>

<section class="mt-0 pb-0 pt-1">
  <app-age-category></app-age-category>
</section>

  <section class="mt-0 pt-0">
    <h2 class="display-4 text-dark bg-background text-center rounded p-2">
      <img
        src="https://glinttoyshoppics.s3.ap-south-1.amazonaws.com/25189682-9d61-4ac3-a1b3-a2901d84b188-20230718220716095.png"
        alt="Logo" class="logo-icon img-fluid"> Blogs
      <p class="text-center" style="font-size: 0.8rem;"> The fascinating world of toys!</p>
    </h2>

    <div class="col-12 mt-0 p-0">
      <div class="container p-1">
        <div class="row">
          <div *ngFor="let blog of blogRecommendations" class="col-12 col-md-4 p-1"> 
            <div class="card shadow border-0 m-1 custom-card" (click)="navigateToBlog(blog.id)">
              <div class="card-body">
                <div class="row p-2">
                  <div class="col-6 col-md-12 d-flex align-items-center">
                    <p class="text-center p-1 m-1">{{ blog.title }}</p>
                  </div>
                  <div class="col-6 col-md-12 d-flex justify-content-center">
                    <img [src]="blog.imageUrl" alt="{{ blog.title }}" class="img-fluid img-custom">
                  </div>
                </div>
                <!-- <div [innerHTML]="blog.shortContent" class="small"></div> -->
              </div>
              <div class="card-footer m-1 p-1">
                <div class="d-flex justify-content-between align-items-center">
                  <p class="small text-dark m-2">{{ blog.readTime }} minute Read</p>
                  <div class="btn btn-primary btn-sm" (click)="navigateToBlog(blog.id)">Read Full</div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>

    </div>
  </section>

  <section class="mt-0 pt-0">
    <div class="col-12 mt-2 mx-auto">
      <app-feedback></app-feedback>
    </div>
  </section>
</div>