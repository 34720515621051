import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ConfigService } from './config.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {


    constructor(private configService: ConfigService) {
    }

    
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    
    let authToken = '';
    let tenant = "";
    if ((localStorage.getItem('userDetails') !== null) && (localStorage.getItem('userDetails') !== '')) {
      authToken = JSON.parse(localStorage.getItem('userDetails')).authToken;
    }
    console.log("intercepted URL " + request.url);
    console.log("intercepted Method " + request.method);

    const config = this.configService.getTenantDetails();
    if (config) {
      tenant = config.name; // Access the properties directly
    } else {
      console.error('Config not loaded yet or failed to load');
    }

    if (request.method === 'OPTIONS') {
      // Handle pre-flight request separately
      const modifiedRequestWithTenant = request.clone({
        headers: request.headers
          .set('Authorization', `Bearer ${authToken}`)
          .set('x-tenant', tenant)
      });
      

      return next.handle(modifiedRequestWithTenant);
    }

  
    const modifiedRequest = request.clone({
      headers: request.headers
        .set('Authorization', `Bearer ${authToken}`)
        .set('x-tenant', tenant)
    });
    
    
    return next.handle(modifiedRequest);
  } 
}
