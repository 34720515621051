import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/services/toaster.service';
import { Toast } from 'src/app/services/toaster';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html'
})
export class ToastComponent implements OnInit {
  toast: Toast | null = null;

  constructor(private toasterService: ToasterService) { }

  ngOnInit(): void {
    this.toasterService.toast$.subscribe((toast: Toast | null) => {
      this.toast = toast;
    });
  }
}
