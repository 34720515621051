enum KidsAge {
    NONE = 0,
    ZEROTOONE = 1,
    TWOTOTHREE = 2,
    FOURTOFIVE = 3,
    SIXTOSEVEN = 4,
    EIGHTTOTEN = 5,
    ELEVENTOTWELVE = 6
  }
  
  export default KidsAge;
  